<template>
    <div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-nav-bar title="客人出行登记列表" left-text="修改密码" left-arrow @click-left="modifyPwd" right-text="注销登陆" @click-right="onClickRight"/>
            <div class="min-height">
                <van-tabs v-model="active">
                    <van-tab title="未送">
                        <div v-if="group.list">
                            <van-collapse v-model="activeNames" accordion>
                                <van-collapse-item
                                        v-for="(item) of group.list"
                                        :title="item.leave_time+' ('+item.people+'人)'"
                                        :key="item.leave_time"
                                        :name="item.leave_time"
                                >
<!--                                    <van-grid :column-num="5">-->
<!--                                        <van-grid-item text="酒店" />-->
<!--                                        <van-grid-item text="尾号" />-->
<!--                                        <van-grid-item text="时间" />-->
<!--                                        <van-grid-item text="人数" />-->
<!--                                        <van-grid-item text="状态" />-->
<!--                                    </van-grid>-->
	                                <van-cell-group :title="item.hotel_name" v-for="(item,index) in group.recordList" :key="item.id">
                                        <van-cell :value="item.leave_time" :label="item.people + '人'">
                                            <!-- cell标题 -->
                                            <template #title>
	                                            <van-icon name="phone-o" />
	                                            <a :href="'tel://' + item.phone" class="custom-title">{{ item.phone }}</a>
                                            </template>
<!--                                            增加按钮-->
                                            <template #default>
                                                <p style="margin: 0">
                                                    {{item.leave_time}}
	                                                <van-tag plain type="primary">{{ item.status }}</van-tag>
                                                </p>
	                                            <van-button size="mini" type="info" @click="setState(item.record_id,item.hotel_name)">已送请点击</van-button>
                                            </template>
                                        </van-cell>
	                                </van-cell-group>

<!--                                    <van-grid v-for="(item,index) in group.recordList" :key="item.id" :column-num="5">
                                        <van-grid-item :text="item.hotel_name" />
                                        <van-grid-item @click="call(item.phone)" :text="item.phone" />
                                        <van-grid-item :text="item.leave_time" />
                                        <van-grid-item :text="item.people+'人'" />
                                        <van-grid-item @click="setState(item.record_id,item.hotel_name,index,'确定已送?')" :text="item.status" />
                                    </van-grid>-->
                                </van-collapse-item>
                            </van-collapse>
                        </div>
                    </van-tab>
                    <van-tab title="已送">
                        <ul class="income">
                            <li>
                                <p>{{month}}</p>
                                <p>本月</p>
                            </li>
                            <li>
                                <p>{{yesterday}}</p>
                                <p>昨日</p>
                            </li>
                            <li>
                                <p>{{today}}</p>
                                <p>今日</p>
                            </li>
                        </ul>
                        <van-grid :column-num="5">
                            <van-grid-item text="酒店" />
                            <van-grid-item text="尾号" />
                            <van-grid-item text="时间" />
                            <van-grid-item text="人数" />
                            <van-grid-item text="状态" />
                        </van-grid>
                        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                            <van-grid v-for="(item) in list" :key="item.id" :column-num="5">
                                <van-grid-item :text="item.hotel_name" />
                                <van-grid-item @click="call(item.phone)" :text="item.phone" />
                                <van-grid-item :text="item.day" />
                                <van-grid-item :text="item.people+'人'" />
                                <van-grid-item :text="item.status" />
                            </van-grid>
                        </van-list>
                    </van-tab>
                    <!--                    <van-tab title="未接">
                        <van-grid :column-num="5">
                            <van-grid-item text="酒店" />
                            <van-grid-item text="尾号" />
                            <van-grid-item text="时间" />
                            <van-grid-item text="人数" />
                            <van-grid-item text="状态" />
                        </van-grid>
                        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                            <van-grid v-for="(item,index) in list" :key="item.id" :column-num="5">
                                <van-grid-item :text="item.hotel_name" />
                                <van-grid-item @click="call(item.phone)" :text="item.phone" />
                                <van-grid-item :text="item.leave_time" />
                                <van-grid-item :text="item.people+'人'" />
                                <van-grid-item @click="setState(item.record_id,item.hotel_name,index,'确定已接?')" :text="item.status" />
                            </van-grid>
                        </van-list>
                    </van-tab>
                    <van-tab title="已接">
                        <van-grid :column-num="5">
                            <van-grid-item text="酒店" />
                            <van-grid-item text="尾号" />
                            <van-grid-item text="时间" />
                            <van-grid-item text="人数" />
                            <van-grid-item text="状态" />
                        </van-grid>
                        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                            <van-grid v-for="(item) in list" :key="item.id" :column-num="5">
                                <van-grid-item :text="item.hotel_name" />
                                <van-grid-item @click="call(item.phone)" :text="item.phone" />
                                <van-grid-item :text="item.leave_time" />
                                <van-grid-item :text="item.people+'人'" />
                                <van-grid-item :text="item.status" />
                            </van-grid>
                        </van-list>
                    </van-tab>-->
                </van-tabs>
            </div>
        </van-pull-refresh>
<!--        展示弹出框-->
	    <van-popup v-model="show" closeable round position="bottom" @closed="closePopup">
		    <div>
			    <van-divider />
                <ul style="padding-left: 16px;">
                    <li>酒店：{{hotel_name}}</li>
                </ul>
			    <van-form @submit="onSubmit">
				    <van-field
						    v-model="verify_code"
						    name="verify_code"
						    label="请输入验证码"
						    placeholder="请输入验证码"
						    :rules="[{ required: true, message: '请输入验证码' }]"
                            type="number"
                            maxlength="10"
                    >
					    <template #button>
                            <van-tag plain type="primary">{{ rand_verify_code }}</van-tag>
					    </template>
                    </van-field>
				    <div style="margin: 16px;">
					    <van-button size="small" round block type="info" native-type="submit">提交</van-button>
				    </div>
			    </van-form>
            </div>
        </van-popup>
    </div>
</template>

<script type="text/javascript">
    import {gray} from "mockjs/src/mock/random/color_dict";

    export default {
        computed: {
            gray() {
                return gray
            }
        },
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                page: 1,
                id : 0,
                activeNames: '',
                active : 0,
                month : 0,
                yesterday : 0,
                today : 0,
                // 状态以及类型
                status : 0,// 0=未送,1=已送
                record_type : 0,// 0=送客，1=接客,
                group : {
                    list : [],
                    recordList : [],
                },
                ws : null,
                leave_time : '',
                isLoading : false,
                show:false,
                verify_code: '',
                hotel_name: '',
                record_id: 0,
                rand_verify_code: Math.round(Math.random()*99999)
            };
        },
        methods: {
            // 注销登陆
            onClickRight(){
                this.$dialog.confirm({
                    title : '立即注销登陆?',
                    message : '注销登陆后请重新登陆'
                }).then(() => {
                    localStorage.removeItem('driver_id');
                    localStorage.removeItem('token');
                    this.$router.push({
                        name : 'driverLogin',
                    });
                }).catch(() =>{
                    console.log('注销异常');
                });
            },
            onLoad() { // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求 axios
                this.$axios.post('/api/hotel_control/getRecordList', this.$qs.stringify({
                    page: this.page, // 请求页数
                    hotel_id : this.hotel_id,
                    status : this.status,
                    record_type: this.record_type
                })).then((response) => {
                    this.page++;
                    this.today = response.data.data.today;
                    this.month = response.data.data.month;
                    this.yesterday = response.data.data.yesterday;
                    for (let i = 0; i < response.data.data.list.length; i++) {
                        this.list.push(response.data.data.list[i]);
                    }
                    // 加载状态结束
                    this.loading = false; // 在每次请求完毕后，需要手动将loading设置为false，表示加载结束
                    // 数据全部加载完成
                    if (this.list.length >= response.data.data.count) { // 当加载的所有的数据大于或等于所有的数据后就停止加载
                        this.finished = true;
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },
            // 拨打电话
            call(tel) {
                window.location.href = 'tel://' + tel;
            },
            // 已送
            setState(id,hotel_name){
                this.show = true;
                this.hotel_name = hotel_name;
                this.record_id = id;
                // 生成随机验证码
                this.rand_verify_code = Math.round(Math.random()*99999);
                this.verify_code = '';
            },
            // 提交本次保存
            onSubmit(){
                if (this.rand_verify_code !== this.verify_code * 1){
                    return this.$toast('验证码不正确');
                }
                this.$axios.post('/api/driver/recordSended',this.$qs.stringify({
                    record_id : this.record_id,
                    verify_code: this.verify_code
                })).then((response) => {
                    this.$toast(response.data.msg);
                    if (response.data.code  == 200){
                        try {
                            this.ws.send('giveAway');
                            console.log('消息发送成功');
                            setTimeout(function () {
                                location.reload();
                            },1000);
                        }catch (e) {
                            this.$toast('通讯异常,3S后重连');
                            setTimeout(function () {
                                location.reload();
                            },1000);
                        }
                    }
                }).catch((error) => {
                    this.$toast(error);
                });
            },
            // 修改密码
            modifyPwd(){
                this.$router.push({
                    name : 'driverPassword',
                });
            },
            // 下拉刷新
            onRefresh(){
                this.$toast('加载成功');
                setTimeout(function () {
                    location.reload();
                    this.isLoading = false;
                },1000);
            },
            // 关闭弹窗
            closePopup(){
                this.record_id = 0;
            }
        },
        // 检查是否登录,应用初始化
        created() {
            var _this = this;
            this.ws = new WebSocket('ws://chat.ynxtw.com:20022');
            this.ws.onopen =function () {
                console.log('................websocker链接正常.............');
            };
            this.ws.onclose = function () {
                _this.ws = new WebSocket('ws://chat.ynxtw.com:20022');
            };
            // 有消息时
            this.ws.onmessage = function (e) {
                if (e.data.indexOf('giveAway') > 1)
                {
                    _this.group.recordList=[];
                    const params =new URLSearchParams();
                    params.append('leave_time',_this.leave_time);
                    _this.$axios.post('/api/driver/getTimeList',params).then(response => {
                        for (let i =0 ; i < response.data.data.list.length;i++)
                        {
                            _this.group.recordList.push(response.data.data.list[i]);
                        }
                    }).catch(() => {
                        _this.$toast('异常');
                    });
                }
            };
            // 请求今天的所有待出行的用户
            this.$axios.get('/api/driver/getTimeGroup').then(response => {
                this.group.list = response.data.data.list;
            }).catch(() => {
                this.$toast('网络异常');
            });
            // 进行心跳的检测
            setInterval(() => {
                this.ws.send('{"type":"ping"}');
            },55000);
        },
        watch : {
            active : function (val) {
                console.log(val);
                if (val === 0) // 因未送和  已送、未接、已接数据格式不同
                {
                    this.$axios.get('/api/driver/getTimeGroup').
                    then(response => {
                        console.log(response);
                        this.group.list = response.data.data.list;
                    }).catch(() => {
                        this.$toast('网络异常');
                    });
                    return;
                }
                this.finished=false;
                this.list=[];
                this.page=1;
                console.log(val);
                switch (val) {
                    case 0:
                        this.status=0;
                        this.record_type = 0;
                        console.log('待送');
                        break;
                    case 1:
                        this.status=1;
                        this.record_type = 0;
                        console.log('已送');
                        break;
                    case 2:
                        this.status=0;
                        this.record_type = 1;
                        console.log('待接');
                        break;
                    case 3:
                        this.status=1;
                        this.record_type = 1;
                        console.log('已接');
                        break;
                }
            },
            activeNames : function (val) {
                this.group.recordList=[];
                this.leave_time = val;
                const params =new URLSearchParams();
                params.append('leave_time',val);
                this.$axios.post('/api/driver/getTimeList',params).then(response => {
                    if (response.data.code == 400) return
                    for (let i =0 ; i < response.data.data.list.length;i++) {
                        this.group.recordList.push(response.data.data.list[i]);
                    }
                    }).catch((e) => {
                        console.log(e)
                        this.$toast('异常');
                    });
            }
        },
        mounted() {
            var _this = this;
            this.ws = new WebSocket('ws://chat.ynxtw.com:20022');
            this.ws.onopen =function () {
                _this.$toast('通讯正常');
            };
            this.ws.onclose = function () {
                _this.ws = new WebSocket('ws://chat.ynxtw.com:20022');
            };
            this.ws.onerror = function () {
                _this.$toast('通讯异常,请刷新页面既可恢复');
            };
            this.ws.onmessage = function (e) {
                if (e.data.indexOf('giveAway') > 1)
                {
                    _this.group.recordList=[];
                    const params =new URLSearchParams();
                    params.append('leave_time',_this.leave_time);
                    _this.$axios.post('/api/driver/getTimeList',params).then(response => {
                        for (let i =0 ; i < response.data.data.list.length;i++)
                        {
                            _this.group.recordList.push(response.data.data.list[i]);
                        }
                    }).catch(() => {
                        _this.$toast('系统异常');
                    });
                }
            };
        }
    }
</script>

<style scoped>
    .van-col {
        padding: 10px 0;
        text-align: center;
    }
    .title {
        color: #969799;
        text-align: center;
    }
    .s{
        color: #F00;
    }
    .show-tel{
        position: absolute;
        top: 36px;
        z-index: 999;
        left: 33%;
    }
    .van-row{
        position: relative;
    }
    .income{
        display: flex;
    }
    .income li{
        flex: 1;
        text-align: center;
    }
    .income li p:last-child{
        font-size: 12px;
        color: #999999;
    }
    .income li p:first-child{
        color: #07c160;
    }
    .van-grid{
        flex-wrap: nowrap;
    }
    .min-height{
        min-height: 550px;
    }
</style>
