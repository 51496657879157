<template>
    <div class="login">
        <van-nav-bar title="总后台登陆" />
        <div class="body">
            <van-cell-group >
                <van-field v-model="phone" label="账号：" placeholder="请输入登陆账号" :autofocus="true" required/>
                <van-field v-model="password" label="密码：" placeholder="请输入登陆密码" type="text" clearable required />
            </van-cell-group>
            <br>
            <br>
            <br>
            <van-button type="primary" block @click="login">立即登陆</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AdminLogin",
        data(){
            return {
                phone : '',
                password : '',
            };
        },
        methods : {
            login : function () {
                let _this = this;
                this.$axios.post('/api/login/adminLogin',_this.$qs.stringify({account : this.phone,password : this.password}))
                    .then(function (response) {
                        if (response.data.code == 200)
                        {
                            _this.$toast(response.data.msg);
                            localStorage.setItem('admin_id',response.data.data.admin_id);
                            _this.$router.push({name : 'AdminIndex'});
                        }else
                            _this.$toast(response.data.msg);
                }).catch(function () {
                    _this.$toast('通信错误');
                });
            }
        }
    }
</script>

<style scoped>

</style>