<template>
    <div>
        <van-nav-bar :title="hotel_name" @click-left="hotelLogin" left-text="返回" left-arrow right-text="查询预约" @click-right="showInfo"/>
        <p class="code-tip">送客信息登记</p>
        <van-cell-group>
            <van-field v-model="phone" label="电 话：" placeholder="请输入乘车人电话" type="tel" clearable required maxlength="11"/>
            <van-field name="radio" label="出发日期" required>
                <template #input>
                    <van-radio-group v-model="day" direction="horizontal" >
                        <van-radio name="1">今日</van-radio>
                        <van-radio name="2">明日</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field readonly clickable required name="datetimePicker" label="预约时间：" :value="leave_time" placeholder="请输入去高铁站时间" @click="showTimeDialog" />
            <van-popup v-model="showPicker" position="bottom">
                <van-datetime-picker type="time" title="请选择乘车时间" @confirm="onConfirm" :formatter="formatter" @cancel="showPicker = false" :filter="filter" @change="play" visible-item-count="7" v-model="currentTime"/>
            </van-popup>
            <van-cell title="乘车人数" required>
                <template #default>
                    <van-stepper v-model="people"  button-size="26"  min="1" max="99"/>
                </template>
            </van-cell>
        </van-cell-group>
        <p class="save">
            <br>
            <br>
            <van-button type="info" round @click="saveData" style="width: 50%;">{{btnText}}</van-button>
        </p>
        <audio src="../../assets/5c8949d53d6db90844.mp3" id="audio2" preload/>
    </div>
</template>

<script>
    export default {
        name: "GiveAway",
        data(){
            return {
                phone: '', // 手机号
                showPicker: false,
                currentTime: (new Date()).getHours()+':00', // 时间选择
                leave_time: '', // 出发时间
                btnText : '立 即 预 约',
                id : 0,
                people  : 1, // 人数
                hotel_name : '',
                hotel_id : 0,
                day : '1',
                record_type : 0,
                currentHour : (new Date()).getHours(),
                selectMinute : "00",
            };
        },
        methods : {
            onConfirm(time) {
                let leaveTime = time.split(":");
                let hour = leaveTime[0];
                let minute = leaveTime[1];
                minute = this.selectMinute;
                time = hour + ':'+ minute;
                this.leave_time = time;
                this.showPicker = false;
            },
            // 时间格式化
            formatter(type, val){
                if (type === 'hour') {
                    return `${val} 时`;
                } else if (type === 'minute') {
                    return `${val} 分`;
                }
                return val;
            },
            // 保存资料
            saveData() {
                if (this.validate()) {
                    this.$dialog.confirm({
                        title: '提示',
                        message: '确认信息无误?'
                    }).then(() => {
                        // 进行预约
                        this.$axios.post('/api/hotel_control/addRecord',this.$qs.stringify(this.$data)).then((response) => {
                            this.$toast(response.data.msg);
                            localStorage.setItem('record_id',response.data.data.record_id);
                            this.phone = '';
                            this.leave_time = '';
                        }).catch((error) => {
                            console.log(error);
                        });
                    }).catch(() => {
                        this.$notify('未预约成功!');
                    });
                }
            },
            // 验证用户输入的信息是否正确
            validate() {
                if (this.phone.length !== 11) {
                    this.$toast('电话不正确');
                    return '';
                }
                if (this.leave_time === ''){
                    this.$toast('时间未选择');
                    return '';
                }
                //////////////  出发前15分钟不可预约
                let date = new Date();
                //// 当日时需要提前15分钟预约
                if (this.day == 1){
                    let operateYear = date.getFullYear();
                    operateYear = operateYear+'-'+  (date.getMonth() + 1);
                    operateYear = operateYear + '-'+date.getDate();
                    let operateTime = new Date(); // 用户操作的时间
                    let submitTime = new Date(operateYear+" "+this.currentTime); // 用户提交的时间
                    ///////////  操作时间戳 和 提交时间戳
                    let operateTimestamp = (operateTime.getTime() / 1000).toFixed(0);
                    let submitTimestamp = (submitTime.getTime() / 1000).toFixed(0);
                    /////////////  两个时间的间隔
                    let twoTimeSpace = submitTimestamp - operateTimestamp;
                    if (twoTimeSpace < 0 || twoTimeSpace <= 900){
                        this.$toast("请提前15分钟预约");
                        return false;
                    }
                }
                let selectTime = this.currentTime.split(":");
                if (this.day == 1 || selectTime[0] >= 14){
                    //////////////    14.30以后不再可以进行预约   //////////////
                    if (date.getHours() > 14){
                        this.$toast("超过14:30后不可预约,请联系相关负责人");
                        return false;
                    }
                    if (date.getHours() == 14 && date.getMinutes() >= 30){
                        this.$toast("超过14:30后不可预约,请联系相关负责人");
                        return false;
                    }
                }
                return true;
            },
            // 时间过滤
            filter(type, options) {
                if (type === 'hour'){
                    return  options.filter((hour => {
                        if (hour >= this.currentHour && hour <= 14){
                            return hour;
                        }
                    }));
                }
                if (type === 'minute'){
                    let minutes;
                    minutes = options.filter((minute => {
                        minute=Number(minute);
                        if (this.currentHour >= 10){
                            if (minute === 0)
                                return minute+'0';
                            else if (minute === 30)
                                return  minute;
                        }else {
                            if (minute === 0)
                                return minute+'0';
                            else if (minute === 20)
                                return  minute;
                            else if (minute === 40)
                                return minute;
                        }
                    }));
                    return minutes;
                }
                return options;
            },
            play(e){
                document.getElementById('audio2').play();
                ///////// 获取用户选择的小时数
                let hours = e.getColumnValue(0);
                hours = hours.replace('时','');
                if (hours >= 10){
                    e.setColumnValues(1,['00 分','30 分']);
                }else {
                    e.setColumnValues(1,['00 分','20 分','40 分']);
                }
                // 获取用户选择的分钟数
                let minute = e.getColumnValue(1);
                this.selectMinute = minute.replace("分",'');
            },
            // 酒店管理员进行登陆
            hotelLogin(){
                history.go(-1);
            },
            // 查看用户预约的信息
            showInfo(){
                if (!localStorage.getItem('record_id'))
                    return this.$toast('未预约');
                this.$router.push({
                    name : 'showInfo'
                });
            },
            /// 显示弹窗
            showTimeDialog(){
                if (this.day == 1 && this.currentHour <= 14){
                    this.showPicker = true;
                }else if (this.day == 2){
                    this.showPicker = true;
                }else {
                    this.showPicker = false;
                }
            }
        },
        mounted() {
            var current_hotel_id = localStorage.getItem('current_hotel_id');
            if (current_hotel_id == 'undefined' || !current_hotel_id)
                return this.$router.push({'name' : '/'});
            else
            {
                this.hotel_id = current_hotel_id;
                this.hotel_name = localStorage.getItem('hotel_name');
            }
        },
        watch : {
            day : {
                handler(oldValue){
                    if (oldValue == 2)
                    {
                        this.$data.currentHour = 6;
                        this.$data.currentTime = "06:00";
                        this.$data.leave_time = "06:00";
                    }
                    else
                    {
                        this.$data.currentHour = (new Date()).getHours();
                        this.$data.leave_time = this.$data.currentHour+":00";
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .save {
        margin: 0 16px;
        text-align: center;
    }
</style>