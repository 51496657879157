<template>
    <div>
        <van-nav-bar left-arrow left-text="返回" @click-left="back" title="用户搜索"/>
        <form action="/">
            <van-search v-model="searchKey" show-action placeholder="请输入搜索关键词">
                <template #action>
                    <div @click="onSearch">搜索</div>
                </template>
            </van-search>
        </form>
        <van-radio-group v-model="radio" class="group">
            <van-radio name="1">手机号</van-radio>
            <van-radio name="2">房间号</van-radio>
            <van-radio name="3">姓名</van-radio>
            <van-radio name="4">
                <van-cell title="日期" :value="date" @click="show = true" />
                <van-calendar v-model="show" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate"/>
            </van-radio>
        </van-radio-group>
        <van-cell-group title="搜索结果">
            <van-row gutter="20">
                <van-col span="12">条数</van-col>
                <van-col span="12">人数</van-col>
            </van-row>
            <van-row gutter="20">
                <van-col span="12">{{num}}</van-col>
                <van-col span="12">{{person}}</van-col>
            </van-row>
            <div v-for="item in results" :key="item.id">
                <van-cell :title="stringJoin(item.name,item.tel,item.hotel,item.person)" @click="detail(item.id)" is-link/>
            </div>
        </van-cell-group>
    </div>
</template>

<script>
    export default {
        name: "Search",
        data (){
            return {
                searchKey : '',
                radio : '1',
                results : [],
                date: '',
                show: false,
                minDate : new Date(2020,5,1),
                maxDate : new Date(),
                num : 0,
                person : 0,
            };
        },
        methods : {
            formatDate(date) {
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            },
            onConfirm(date){
                this.show = false;
                this.date = this.formatDate(date);
            }
            ,
            back(){
                this.$router.push({
                    name : 'listed'
                });
            },
            onSearch() {
                if (Number(this.radio) === 4){
                    this.searchKey = this.date;
                }
                this.$axios.post('/index/record/search',{
                    key : this.searchKey,
                    keyType : this.radio,
                }).then((response) => {
                    if (response.data.code > 0){
                        this.results=response.data.lists;
                        localStorage.setItem('result',JSON.stringify(response.data.lists));
                        this.num = response.data.timeNum;
                        this.person = response.data.timePerson;
                    }
                })
            },
            // 详细信息
            detail(id){
                this.$router.push({
                    name : 'detail',
                    params : {
                        id : id
                    }
                })
            },
            stringJoin (name,tel,hotel,person){
                return '姓名:'+name+" 电话:"+tel+" 房号:"+hotel+' 人数:'+person;
            },
        },
        created() { // 检查是否登录
            if (!this.$isDev){
                if (!localStorage.getItem('login')) {
                    this.$router.push({
                        name: 'login',
                    });
                }else {
                    this.results = JSON.parse(localStorage.getItem('result'));
                }
            }
        }
    }
</script>

<style scoped>
    .group{
        padding: 0 16px;
    }
    .van-radio{
        padding: 4px 0;
    }
    .van-radio__label .van-cell{
        display: block;
    }
    .van-cell  .van-cell__title,.van-cell .van-cell__value{
        display: inline-block;
        padding: 0 5px;
    }
</style>