/**
 *  路由器
 * */
import VueRouter from 'vue-router';
import Vue from 'vue';
import Index from "../components/Index";
import Show from "../components/Show";
import List from "../components/List";
import Login from "../components/Login";
import Listed from "../components/Listed";
import Search from "../components/Search";
import Detail from "../components/Detail";
import RoomState from "../components/RoomState";
import AddRoom from "../components/AddRoom";
import HotelLogin from "../page/HotelLogin";
import AdminLogin from "../page/login/AdminLogin";
import DriverLogin from "../page/login/DriverLogin";
import HotelEmpty from "../page/empty/HotelEmpty";
import HotelIndex from "../page/hotel/Index";
import DriverIndex from "../page/driver/Index";
import AdminIndex from '../page/admin/index';
import AdminPassword from '../page/admin/Password';
import AdminDriver from '../page/admin/Driver';
import AddDriver from '../page/admin/AddDriver';
import AdminHotel from '../page/admin/Hotel';
import AddHotel from '../page/admin/AddHotel';
import QrCode from "../page/admin/QrCode";
import DriverPassword from '../page/driver/Password';
import HotelPassword from '../page/hotel/Password';
import AdminRecord from '../page/admin/Record';
import GiveAway from '../page/hotel/GiveAway';
import PickUp from "../page/hotel/PickUp";
import ShowInfo from "../page/hotel/ShowInfo";
import Setting from "../page/hotel/Setting";

const routes = [
    {
        path: '/show',
        name: 'show',
        component: Show,
        meta: {
            index: 1,
        },
        params : {
            isModify : 1
        }
    },
    {
        path: '/list',
        name: 'list',
        component: List,
        meta: {
            index: 1,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            index: 0,
        }
    },
    {
        path : '/listed',
        name : 'listed',
        component : Listed,
        meta : {
            index : 1,
        }
    },
    {
        path : '/search',
        name : 'search',
        component : Search,
        meta : {
            index : 1,
        }
    },
    {
        path : '/detail',
        name : 'detail',
        component : Detail,
        meta : {
            index : 1,
        }
    },
    {
        path : '/roomState',
        name : 'roomState',
        component : RoomState,
        meta : {
            index : 0,
        }
    },
    {
        path : '/addRoom',
        name : 'addRoom',
        component : AddRoom,
        meta : {
            index : 0,
        }
    },
    {
        path : '/hotelLogin',
        name : 'hotelLogin',
        component : HotelLogin
    },
    {
        path : '/adminLogin',
        name : 'adminLogin',
        component : AdminLogin
    },
    {
        path : '/driverLogin',
        name : 'driverLogin',
        component : DriverLogin,
    },
    {
        path : '/',
        name : 'emptyHotel',
        component : HotelEmpty,
    },
    {
        path : '/hotelIndex',
        name : 'hotelIndex',
        component : HotelIndex,
    },
    {
      path : '/driverIndex',
      name : 'driverIndex',
      component : DriverIndex,
    },
    {
        path : "/AdminIndex",
        name : 'AdminIndex',
        component : AdminIndex,
    },
    {
        path : '/admin/Password',
        name : 'AdminPassword',
        component : AdminPassword,
    },
    {
        path : '/admin/driver',
        name : 'AdminDriver',
        component : AdminDriver,
    },
    {
        path : '/admin/addDriver',
        name : 'AddDriver',
        component : AddDriver,
        meta : {
            driver_id : 0,
        }
    },
    {
        path : '/admin/hotel',
        name : 'AdminHotel',
        component : AdminHotel,
    },
    {
      path : '/admin/addHotel',
      name : 'AddHotel',
      component : AddHotel,
    },
    {
      path : '/qrcode',
      name : 'qrcode',
      component : QrCode,
    },
    {
        path : '/driver/password',
        name : 'driverPassword',
        component : DriverPassword,
    },
    {
        path : '/hotel/password',
        name : 'hotelPassword',
        component : HotelPassword,
    },
    {
        path : '/admin/record',
        name : 'AdminRecord',
        component : AdminRecord,
    },
    {
        path : '/hotel/giveAway',
        name : 'GiveAway',
        component : GiveAway
    },
    {
        path : '/hotel/pickUp',
        name : 'PickUp',
        component : PickUp
    },
    {
        path : '/hotel/showInfo',
        name : 'showInfo',
        component : ShowInfo,
    },
    {
        path : '/hotel/setting',
        name : "setting",
        component : Setting,
    },
    {
        path: '/:hotel_id',
        name: 'index',
        component: Index,
        meta: {
            index: 0,
            title : '高铁站接送登记',
        },
        params : {
            isModify : 0
        }
    },
];
const router = new VueRouter({
    routes
});
Vue.use(VueRouter);
export default router;