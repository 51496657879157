<template>
    <div>
        <van-nav-bar
                title="设置"
        />
        <div class="body">
            <van-cell-group>
                <van-cell title="查看二维码" is-link @click="showQrCode" />
                <van-cell title="修改密码" is-link to="/hotel/password"/>
                <van-cell title="注销登陆" is-link @click="logout"/>
            </van-cell-group>
        </div>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o" @click="goBack">接送登记</van-tabbar-item>
            <van-tabbar-item icon="label" to="/hotelIndex">酒店查询</van-tabbar-item>
            <van-tabbar-item icon="setting-o" to="/hotel/setting">设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    export default {
        name: "Setting",
        data(){
            return {
                active : 2
            };
        },
        methods : {
            goBack : function () {
                this.$router.push({
                    name : 'index',
                    params : {
                        hotel_id : localStorage.getItem('current_hotel_id')
                    }
                });
            },
            logout(){
                this.$dialog.confirm({
                    title : '注销登陆',
                    message : '注销后需重新登陆'
                }).then(() => {
                    localStorage.removeItem('hotel_id');
                    this.$router.push({
                        name : 'hotelLogin',
                    });
                }).catch(() => {});
            },
            // 查看酒店二维码
            showQrCode(){
                this.$router.push({
                    name : 'qrcode',
                    params : {
                        hotel_id : localStorage.getItem('hotel_id')
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>