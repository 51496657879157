<template>
    <div>
        <van-nav-bar title="用户详细信息" left-text="返回" left-arrow @click-left="back"/>
        <van-cell-group>
            <van-cell title="用户电话" :value="info.tel" @click="tel(info.tel)"/>
            <van-cell title="用户姓名" :value="info.name"/>
            <van-cell title="所住房间" :value="info.hotel"/>
            <van-cell title="登记时间" :value="info.add_time"/>
            <van-cell title="预约时间" :value="this.info.leave"/>
            <van-cell title="上车时间" :value="info.send_time"/>
            <van-cell title="乘车人数" :value="info.person+'人'"/>
            <van-cell title="当日入住条数" :value="info.timeNum+'条'"/>
            <van-cell title="当日入住人数" :value="info.timePerson+'人'"/>
        </van-cell-group>
    </div>
</template>

<script>
    export default {
        name: "Detail",
        data (){
          return {
              info : {
                  tel : '',
                  name : '',
                  hotel : '',
                  send_time : '',
                  add_time : '',
                  leave : '',
              }
          }
        },
        methods : {
            back(){
                this.$router.go(-1);
            },
            tel(tel){
                location.href='tel://'+tel;
            }
        },
        created() {
            if (!localStorage.getItem('login')) {
                this.$router.push({
                    name: 'login',
                });
            }
            let id = this.$route.params.id;
            console.log('传输的id为');
            console.log(id);
            this.$axios.post('/index/record/getDetail',{
                id : id
            }).then((response) => {
                if (response.data.code > 0){
                    this.info = response.data.data;
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }
</script>

<style scoped>

</style>