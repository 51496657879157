<template>
    <div>
        <van-nav-bar
                :title="hotel_name"
                @click-left="hotelLogin"
                left-text="返回"
                left-arrow
        />
        <p class="code-tip">客人信息</p>
        <van-cell-group>
            <van-field v-model="phone" label="电 话：" placeholder="" type="tel" clearable required />
            <van-field v-model="leave_time" label="乘车时间：" placeholder="" type="text" clearable required />
            <van-field v-model="people" label="出发人数：" placeholder="" type="text" clearable required />
        </van-cell-group>
    </div>
</template>

<script>
    export default {
        name: "ShowInfo",
        data(){
            return {
                phone: '', // 手机号
                leave_time: '', // 出发时间
                people  : 1, // 人数
                hotel_name : '',
                hotel_id : 0,
            };
        },
        methods : {
            // 酒店管理员进行登陆
            hotelLogin(){
                history.go(-1);
            },
        },
        mounted() {
            let record_id = localStorage.getItem('record_id');
            this.$axios.get('/api/hotel_control/getRecord?record_id='+record_id).then(response => {
                console.log(response)
                this.phone = response.data.data.list.phone;
                this.leave_time = response.data.data.list.leave_time;
                this.people = response.data.data.list.people;
            }).catch(() => {
                this.$toast('系统繁忙');
            });
        },
    }
</script>

<style scoped>
    .save {
        margin: 0 16px;
        text-align: center;
    }
</style>