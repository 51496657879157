<template>
	<div>
		<van-nav-bar title="登录" ></van-nav-bar>
		<van-cell-group>
			<!-- 输入任意文本 -->
			<van-field v-model="text" label="账号" placeholder ='请输入登录账号' clearable required/>
			<!-- 输入密码 -->
			<van-field v-model="password" type="password" label="密码" placeholder="请输入登录密码" clearable required/>
		</van-cell-group>
		<br>
		<p class="save" @click="login">
			<van-button block type="primary">登录</van-button>
		</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				text: '',
				password: '',
			};
		},
		methods : {
			login(){
				this.$axios.post('/index/record/login',this.$data).then((response) => {
					if(response.data.code > 0){
						localStorage.setItem('login',1);
						this.$toast(response.data.message);
						this.$router.push({
							name : 'list',
						});
					}else{
						this.$toast(response.data.message);
					}
				}).catch((error) => {
					console.log(error);
				});
			}
		}
	};
</script>

<style>
</style>
