<template>
    <div>
        <van-nav-bar :title="title" left-arrow left-text="返回" @click-left="goBack"/>
        <van-tabs v-model="active">
            <van-tab title="未送">
                <van-grid :column-num="5">
                    <van-grid-item text="尾号" />
                    <van-grid-item text="酒店"/>
                    <van-grid-item text="时间" />
                    <van-grid-item text="人数" />
                    <van-grid-item text="状态" />
                </van-grid>
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-grid v-for="(item,index) in list" :key="item.record_id" :column-num="5">
                        <van-grid-item @click="call(item.phone)" :text="item.phone.substr(-4,4)" />
                        <van-grid-item :text="item.hotel_name"/>
                        <van-grid-item :text="item.leave_time" />
                        <van-grid-item :text="item.people+'人'" />
                        <van-grid-item @click="setState(item.record_id,item.phone,index)" :text="item.status"/>
                    </van-grid>
                </van-list>
            </van-tab>
            <van-tab title="已送">
                <ul class="income">
                    <li>
                        <p>{{month}}</p>
                        <p>本月</p>
                    </li>
                    <li>
                        <p>{{yesterday}}</p>
                        <p>昨日</p>
                    </li>
                    <li>
                        <p>{{today}}</p>
                        <p>今日</p>
                    </li>
                </ul>
                <van-grid>
                    <van-grid-item text="尾号" />
                    <van-grid-item text="时间" />
                    <van-grid-item text="人数" />
                    <van-grid-item text="状态" />
                </van-grid>
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-grid v-for="(item) in list" :key="item.record_id" :column-num="5">
                        <van-grid-item @click="call(item.phone)" :text="item.phone.substr(-4,4)" />
                        <van-grid-item :text="item.hotel_name"/>
                        <van-grid-item :text="item.leave_time" />
                        <van-grid-item :text="item.people+'人'" />
                        <van-grid-item :text="item.status"/>
                    </van-grid>
                </van-list>
            </van-tab>
            <!--            <van-tab title="未接">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-grid v-for="(item,index) in list" :key="item.record_id" :column-num="5">
                        <van-grid-item @click="call(item.phone)" :text="item.phone.substr(-4,4)" />
                        <van-grid-item :text="item.hotel_name"/>
                        <van-grid-item :text="item.leave_time" />
                        <van-grid-item :text="item.people+'人'" />
                        <van-grid-item @click="setState(item.record_id,item.phone,index)" :text="item.status"/>
                    </van-grid>
                </van-list>
            </van-tab>
            <van-tab title="已接">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-grid v-for="(item) in list" :key="item.record_id" :column-num="5">
                        <van-grid-item @click="call(item.phone)" :text="item.phone.substr(-4,4)" />
                        <van-grid-item :text="item.hotel_name"/>
                        <van-grid-item :text="item.leave_time" />
                        <van-grid-item :text="item.people+'人'" />
                        <van-grid-item :text="item.status"/>
                    </van-grid>
                </van-list>
            </van-tab>-->
        </van-tabs>
    </div>
</template>
<script>
    export default {
        name: "Record",
        created() {
            if (this.$isDev)
                return;
            let admin_id = localStorage.getItem('admin_id');
            if (!admin_id)
            {
                this.$router.push({
                    name : 'adminLogin'
                });
            }
        },
        data(){
            return {
                list: [],
                loading: false,
                finished: false,
                page: 1,
                id : 0,
                active : 0,
                hotel_id : 0,
                month : 0,
                yesterday : 0,
                today : 0,
                title: '客人出行登记列表',
                status : 0,// 0=未送,1=已送
                record_type : 0,// 0=送客，1=接客
            };
        },
        methods : {
            onLoad() { // 异步更新  待送客人 数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求 axios
                this.$axios.post('/api/hotel_control/getRecordList', this.$qs.stringify({
                    page: this.page, // 请求页数
                    hotel_id : this.hotel_id,
                    status : this.status,
                    record_type: this.record_type,
                    admin_id : 1,
                })).then((response) => {
                    this.page++;
                    for (let i = 0; i < response.data.data.list.length; i++) {
                        this.list.push(response.data.data.list[i]);
                    }
                    // 加载状态结束
                    this.loading = false; // 在每次请求完毕后，需要手动将loading设置为false，表示加载结束
                    // 数据全部加载完成
                    if (this.list.length >= response.data.data.count) { // 当加载的所有的数据大于或等于所有的数据后就停止加载
                        this.finished = true;
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },
            // 取消
            setState(record_id,phone,index){
                this.$dialog.confirm({
                    title : '提示',
                    message : phone+'-确定取消?'
                }).then(() => {
                    this.$axios.post('/api/hotel_control/cancelRecord',this.$qs.stringify({record_id})).then((response) => {
                        this.$toast(response.data.msg);
                        if (response.data.code === 200)
                            this.list.splice(index,1);
                    }).catch((error) => {
                        console.log(error);
                    });
                }).catch((error) => {
                    console.log(error);
                })
            },
            goBack(){
                history.go(-1);
            },
            // 拨打电话
            call(tel) {
                window.location.href = 'tel://' + tel;
            },
        },
        watch : {
            active : function (val) {
                this.finished=false;
                this.list=[];
                this.page=1;
                console.log(val);
                switch (val) {
                    case 0:
                        this.status=0;
                        this.record_type = 0;
                        console.log('待送');
                        break;
                    case 1:
                        this.status=1;
                        this.record_type = 0;
                        console.log('已送');
                        break;
                    case 2:
                        this.status=0;
                        this.record_type = 1;
                        console.log('待接');
                        break;
                    case 3:
                        this.status=1;
                        this.record_type = 1;
                        console.log('已接');
                        break;
                }
            }
        }
    }
</script>

<style scoped>

</style>