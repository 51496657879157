<template>
    <div>
        <van-nav-bar title="添加司机" left-text="返回" left-arrow @click-left="goBack"/>
        <h1 style="padding-left: 20px;">司机管理</h1>
        <van-form @submit="onSubmit">
            <van-field
                    v-model="driver_name"
                    name="name"
                    label="姓名"
                    placeholder="请输入司机姓名"
                    :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
                    v-model="phone"
                    type="text"
                    name="phone"
                    label="手机号"
                    placeholder="请填写手机号"
                    :rules="[{ required: true, message: '请填写手机号' }]"
            />
            <van-field
                    v-model="phone2"
                    type="text"
                    name="phone2"
                    label="手机号2"
                    placeholder="请输入第二个手机号"
            />
            <van-field
                    v-model="password"
                    type="text"
                    label="登陆密码"
                    name="password"
                    placeholder="请输入登陆密码"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">{{btn_text}}</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    export default {
        name: "AddDriver",
        data(){
            return {
                driver_name: '',
                phone: '',
                phone2 : '',
                password : '123456',
                btn_text : '立即添加',
                driver_id : 0
            };
        },
        methods : {
            onSubmit(values) {
                var _this = this;
                this.$dialog.confirm({
                    title : '提示',
                    message : this.btn_text,
                }).then(() => {
                    values.driver_id=this.driver_id;
                    _this.$axios.post('/api/index/addDriver',this.$qs.stringify({
                        driver_id : _this.driver_id,
                        driver_name : _this.driver_name,
                        phone : _this.phone,
                        phone2 : _this.phone2,
                        password : _this.password,
                    })).then((response) => {
                        _this.$toast(response.data.msg);
                    }).catch(() => {
                        _this.$toast('网络错误');
                    })
                }).catch(() => {
                    console.log('已取消');
                })
            },
            goBack(){
                history.go(-1);
            }
        },
        created(){
            var admin_id = localStorage.getItem('admin_id');
            if (!admin_id)
            {
                this.$router.push({
                    name : 'adminLogin'
                });
            }
            let driver_id = this.$route.params.driver_id;
            if (driver_id)
            {
                this.$axios.get('/api/index/getDrivers?driver_id='+driver_id).then((response) => {
                    this.driver_name = response.data.data.list.driver_name;
                    this.phone = response.data.data.list.phone;
                    this.phone2 = response.data.data.list.phone2;
                    this.driver_id = response.data.data.list.driver_id;
                });
                this.btn_text = '立即修改';
            }
        }
    }
</script>

<style scoped>

</style>