<template>
    <div>
        <van-nav-bar title="房间管理" left-text="返回" left-arrow @click-left="back"/>
        <van-form @submit="onSubmit">
            <van-field v-model="room" name="room_number" label="房间号" placeholder="请输入房间号" :rules="[{ required: true, message: '请输入房间号' }]"/>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">添加</van-button>
            </div>
        </van-form>
        <br>
        <div style="margin: 16px;">
            <van-button round block type="danger" @click="delRoom">删除</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AddRoom",
        methods : {
            back (){
                this.$router.go(-1);
            },
            onSubmit(values) {
                this.$dialog.confirm({
                    title : '提示',
                    message : '确定添加'+values.room_number+'房间?'
                }).then(() => {
                    this.$axios.post('/index/record/addRoom',{
                        room_number : values.room_number
                    }).then((response) => {
                        this.$toast(response.data.message);
                    });
                }).catch((error) => {
                    console.log(error);
                })
            },
            delRoom(){
                this.$dialog.confirm({
                    title : '提示',
                    message : '确定删除'+this.room+'房间?'
                }).then(()=>{
                    this.$axios.post('/index/record/delRoom',{
                        room_name : this.room,
                    }).then((response) => {
                        this.$toast(response.data.message);
                    });
                });
            }
        },
        data (){
            return {
                room : '',
            };
        },
        created() {
            if (!localStorage.getItem('login')) {
                this.$router.push({
                    name: 'login',
                });
            }
        }
    }
</script>

<style scoped>

</style>