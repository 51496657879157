<template>
    <div>
        <van-nav-bar title="二维码" left-arrow left-text="返回" @click-left="goBack"/>
        <img :src="src" alt="1">
    </div>
</template>

<script>
    import QrCode from 'qrcode';
    export default {
        name: "QrCode",
        components : QrCode,
        data(){
            return {
                src : '',
            };
        },
        mounted() {
            console.log(location.port);
            let host = location.host;
            let mergeHost = 'http://'+host+'/#/'+this.$route.params.hotel_id;
            console.log(this.$route.params.hotel_id);
            QrCode.toDataURL(mergeHost).then(url => {
                this.src = url;
            });
        },
        methods : {
            goBack(){
                history.go(-1);
            }
        },
        created() {}
    }
</script>

<style scoped>
    img{
        width: 90%;
        position: relative;
        left: 50%;
        transform: translate(-50%,40%);
    }
</style>