<template>
	<div>
		<van-nav-bar v-if="is_show" title="登记信息" left-text="返回" @click-left="onClickLeft" left-arrow right-text="修改" @click-right="modifyInfo"/>
		<van-nav-bar v-else title="登记信息" left-text="返回" @click-left="onClickLeft" left-arrow right-text="删除" @click-right="modifyInfo"></van-nav-bar>
		<van-cell-group>
			<van-field class="tip" v-model="name" label="姓名"  readonly/>
			<van-field v-model="phone" label="电话"   readonly/>
			<van-field v-model="hotel" label="房间号"  readonly />
			<van-field v-model="leave" label="出发时间"  readonly/>
			<van-field v-model="person" label="乘车人数" readonly/>
		</van-cell-group>
		<br>
		<p class="save" v-if="is_show" style="padding: 0 30px;">
			<van-button type="info" block round disabled>已预约成功</van-button>
			<van-button type="danger" round @click="cancel" class="cancel-btn">取消预约</van-button>
		</p>
		<p class="save info" v-else>本次接送服务已结束，欢迎下次光临!</p>
	</div>
</template>

<script>
	export default {
		methods: {
			onClickLeft() {
				this.$router.push({
					name: 'index',
				});
			},
			// 修改提交的信息
			modifyInfo(){
				if (!this.is_show){
					localStorage.removeItem('user');
					this.$router.go(-1);
				}else {
					this.$router.push({
						name : 'index',
						params:{
							isModify : 1,
						}
					});
				}
			},
			// 取消预约
			cancel(){
				this.$dialog.confirm({
					title : '提示',
					message : '确认取消本次预约?'
				}).then(() => {
					let info = JSON.parse(localStorage.getItem('user'));
					if (info){
						this.$axios.post('/index/record/cancel',{
							id : info.id,
						}).then((response) => {
							if (response.data.code > 0){
								localStorage.setItem('user',null);
								this.$toast(response.data.message);
								this.$router.push({
									name : 'index'
								});
							}
							this.$toast(response.data.message);
						}).catch((error) => {
							console.log(error);
						});
					}
				}).catch(() => {
					console.log(123);
				})
			}
		},
		data(){
			return {
				name : '未录入',
				phone : '未录入',
				hotel : '未录入',
				leave : '未录入',
				person : '未录入',
				is_show : true,
			};
		},
		created(){
			const info=JSON.parse(localStorage.getItem('user'));
			if (info){
				this.name=info.name;
				this.phone=info.phone;
				this.hotel=info.hotel;
				this.leave=info.leave;
				this.person=info.person+'人';
				// 查看该条记录是否已经被更改状态
				this.$axios.post('/index/record/getDetail',{
					id : info.id
				}).then((response) => {
					if (response.data.data.state === 1){
						this.is_show=false;
					}
				});
			}
		}
	}
</script>

<style scoped="scoped">
	input{
		color: #1989fa!important;
	}
	.info{
		text-align: center;
		color: #1989fa;
	}
	.cancel-btn{
		position: absolute;
		bottom: 160px;
		left: 40%;
	}
</style>
