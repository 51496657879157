<template>
	<div id="app">
		<!-- router-view 是一个容器然后需要显示的内容就在这里面,路由需要和router-view配合使用 router-link to 此时的链接就是组件-->
		<transition :name="transitionName">
			<router-view>
				<Index/>
			</router-view>
		</transition>
		<div class="copyright" @click="contact">&copy;云南学堂网科技有限公司--提供技术支持</div>
	</div>
</template>

<script>
	import Index from './components/Index.vue';
	export default {
		name: 'App',
		components: {
			Index,
		},
		// 侦听
		watch: {
			$route(to, from) {
				// 如果to索引大于from索引,判断为前进状态,反之则为后退状态 
				if (to.meta.index > from.meta.index) {
					// 设置动画名称
					this.transitionName = 'van-slide-left';
				} else {
					this.transitionName = 'van-slide-right';
				}
			}
		},
		data () {
			return {
				transitionName : '',
			}
		},
		methods : {
			contact : function () {
				location.href='tel://15987064352';
			}
		}
	}
</script>

<style>
	.body{
		padding: 15px;
		margin-top: 50px;
	}
	.copyright{
		position: fixed;
		display: none;
		bottom: 0;
		text-align: center;
		width: 100%;
		font-size: 12px;
		padding: 10px 0;
		background-color: #f2f3f5;
		color: #999999;
	}
</style>