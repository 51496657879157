<template>
	<div>
		<van-nav-bar title="客户出行登记列表" right-text="已送 >" @click-right="onClickRight" left-text="房态管理" @click-left="roomState"/>
		<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
			<van-row type="flex" class="title">
				<van-col span="3">序号</van-col>
				<van-col span="7">姓名</van-col>
				<van-col span="4">房号</van-col>
				<van-col span="4">电话</van-col>
				<van-col span="5">时间</van-col>
				<van-col span="5">人数</van-col>
				<van-col span="4">状态</van-col>
			</van-row>
			<van-row type="flex" v-for="(item,index) in list" :key="item.id">
				<van-col span="3">{{index+1}}</van-col>
				<van-col span="7" @click="detial(item.id)">{{item.name}}</van-col>
				<van-col span="4" >{{item.hotel}}</van-col>
				<van-col span="4">
					<van-icon name="phone" @click="call(item.tel)" color="#07c160" size="24px"/>
				</van-col>
				<van-col span="5" >{{item.leave}}</van-col>
				<van-col span="5" >{{item.person}}人</van-col>
				<div v-if="item.is_cancel == 1">
					已取消
				</div>
				<van-col v-else @click="setState(item.id,item.hotel,index)" span="4" class="s">未送</van-col>
				<div align="center" class="show-tel">{{item.tel}}</div>
			</van-row>
		</van-list>
		<div style="padding: 30px 20px;" v-html="text"></div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				list: [
					{"id":614,"name":"朱兴达","tel":"13252089963","hotel":"902","leave":"06:05","add_time":1600334493,"update_time":0,"state":0,"send_time":0,"is_delete":0,"person":1,"auto_time":0,"date":"9月17日"},
					{"id":617,"name":"佘邦友","tel":"19187652389","hotel":"718","leave":"06:35","add_time":1600345136,"update_time":0,"state":0,"send_time":0,"is_delete":0,"person":1,"auto_time":0,"date":"9月17日"},
					{"id":612,"name":"李彩波","tel":"18156305092","hotel":"904","leave":"07:35","add_time":1600316657,"update_time":0,"state":0,"send_time":0,"is_delete":0,"person":3,"auto_time":0,"date":"9月17日"},
					{"id":616,"name":"刘智慧","tel":"13839666685","hotel":"911","leave":"07:35","add_time":1600342490,"update_time":0,"state":0,"send_time":0,"is_delete":0,"person":1,"auto_time":0,"date":"9月17日"},
					{"id":615,"name":"王绍美","tel":"18468229042","hotel":"907","leave":"10:05","add_time":1600336659,"update_time":0,"state":0,"send_time":0,"is_delete":0,"person":1,"auto_time":0,"date":"9月17日"}
					],
				loading: false,
				finished: false,
				page: 1,
				id : 0,
				room : 0,
				text : ''
			};
		},
		methods: {
			roomState(){
				this.$router.push({
					name : 'roomState',
				});
			},
			onClickRight(){
				this.$router.push({
					name : 'listed',
				});
			},
			onLoad() { // 异步更新数据
				if (!this.$isDev){
					// setTimeout 仅做示例，真实场景中一般为 ajax 请求 axios
					this.$axios.post('/index/record/infoList', {
						page: this.page,
						isFinished : 0,
					}).then((response) => {
						this.list=[];
						this.page++;
						for (let i = 0; i < response.data.list.length; i++) {
							this.list.push(response.data.list[i]);
						}
						this.text=response.data.text;
						// 加载状态结束
						this.loading = false; // 在每次请求完毕后，需要手动将loading设置为false，表示加载结束
						// 数据全部加载完成
						if (this.list.length >= response.data.count) { // 当加载的所有的数据大于或等于所有的数据后就停止加载
							this.finished = true;
						}
					}).catch((error) => {
						console.log(error);
					});
				}
			},
			// 拨打电话
			call(tel) {
				window.location.href = 'tel://' + tel;
			},
			// 已送
			setState(id,hotel,index){
				this.id = id;
				this.room = hotel;
				this.$dialog.confirm({
					title : '提示',
					message : this.room+'-确定已送?'
				}).then(() => {
					this.$axios.post('/index/record/transfed',{
						id : this.id,
					}).then((response) => {
						if (response.data.code > 0){
							this.list.splice(index,1);
						}
						this.$toast(response.data.message);
					}).catch((error) => {
						console.log(error);
					});
				}).catch((error) => {
					console.log(error);
				})
			},
			detial(id){
				this.$router.push({
					name : 'detail',
					params : {
						id : id
					}
				});
			}
		},
		created() { // 检查是否登录
			if (!this.$isDev){
				if (!localStorage.getItem('login')) {
					this.$router.push({
						name: 'login',
					});
				}
			}
			localStorage.removeItem('result');
		}
	}
</script>

<style>
	.van-col {
		padding: 10px 0;
		text-align: center;
	}

	.title {
		color: #969799;
		text-align: center;
	}
	.s{
		color: #F00;
	}
	.show-tel{
		position: absolute;
		top: 36px;
		z-index: 999;
		left: 33%;
	}
	.van-row{
		position: relative;
	}
</style>
