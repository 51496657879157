<template>
    <div>
        <van-nav-bar title="酒店管理"
                     left-text="返回"
                     left-arrow
                     @click-left="goBack"
                     right-text="二维码"
                     @click-right="getCode"
        />
        <h1 style="padding-left: 20px;">酒店管理</h1>
        <van-form @submit="onSubmit">
            <van-field
                    v-model="hotel_name"
                    name="hotel_name"
                    label="酒店名称"
                    placeholder="请输入酒店名称"
                    :rules="[{ required: true, message: '请填写酒店名称' }]"
            />
            <van-field
                    v-model="name"
                    name="name"
                    label="姓名"
                    placeholder="请输入管理员姓名"
            />
            <van-field
                    v-model="phone"
                    type="text"
                    name="phone"
                    label="手机号"
                    placeholder="请填写手机号"
                    :rules="[{ required: true, message: '请填写手机号' }]"
            />
            <van-field
                    v-model="phone2"
                    type="text"
                    name="phone2"
                    label="手机号2"
                    placeholder="请输入第二个手机号"
            />
            <van-field
                    v-model="password"
                    type="text"
                    label="登陆密码"
                    name="password"
                    placeholder="请输入登陆密码"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">{{btn_text}}</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    export default {
        name: "AddDriver",
        data(){
            return {
                hotel_name: '',
                name : '',
                phone: '',
                phone2 : '',
                password : '123456',
                hotel_id : 0,
                btn_text : '添加酒店',
            };
        },
        methods : {
            onSubmit(values) {
                values.hotel_id = this.hotel_id;
                this.$dialog.confirm({
                    title : '提示',
                    message : this.btn_text
                }).then(() => {
                    this.$axios.post('/api/index/addHotel',this.$qs.stringify(values)).then((response) => {
                        this.$toast(response.data.msg);
                    }).catch(() => {
                        this.$toast('网络错误');
                    });
                }).catch(() => {
                    console.log(this);
                })
            },
            goBack(){
                history.go(-1);
            },
            getCode(){
                if (this.hotel_id)
                    this.$router.push({name : 'qrcode',params : {hotel_id : this.hotel_id}});
                else
                    this.$toast.fail('请添加后查看');
            }
        },
        created(){
            let admin_id = localStorage.getItem('admin_id');
            if (!admin_id)
                this.$router.push({name : 'adminLogin'});
            let driver_id = this.$route.params.hotel_id;
            this.hotel_id = driver_id;
            if (driver_id)
                this.$axios.get('/api/index/getHotelList?hotel_id='+driver_id).then((response) => {
                    this.hotel_id = response.data.data.list.hotel_id;
                    this.hotel_name = response.data.data.list.hotel_name;
                    this.name = response.data.data.list.name;
                    this.phone = response.data.data.list.phone;
                    this.phone2 = response.data.data.list.phone2;
                    this.password = response.data.data.list.password;
                    this.btn_text = '立即修改';
                });
        }
    }
</script>

<style scoped>

</style>