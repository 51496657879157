<template>
    <div>
        <van-empty description="该酒暂未开通该服务"/>
    </div>
</template>

<script>
    export default {
        name: "HotelEmpty"
    }
</script>

<style scoped>

</style>