<template>
    <div>
        <van-nav-bar title="酒店列表" left-text="返回" @click-left="goBack" left-arrow/>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <van-grid>
                <van-grid-item text="名称" />
                <van-grid-item text="电话1" />
                <van-grid-item text="电话2" />
                <van-grid-item text="操作" />
            </van-grid>
            <van-grid v-for="(item,index) in list" :key="item.hotel_id">
                <van-grid-item :text="item.hotel_name" @click="editDriver(item.hotel_id)"/>
                <van-grid-item :text="item.phone" @click="call(item.phone)"/>
                <van-grid-item :text="item.phone2" @click="call(item.phone2)"/>
                <van-grid-item>
                    <van-button type="danger" size="mini" @click="delDriver(item.hotel_id,item.hotel_name,index)">删除</van-button>
                </van-grid-item>
            </van-grid>
        </van-list>
        <div class="btn-block">
            <van-button type="primary" block to="/admin/addHotel">添加酒店</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Hotel",
        data(){
            return {
                loading : false,
                finished: false,
                list: [],
            };
        },
        methods : {
            goBack(){
                history.go(-1);
            },
            // 获取司机列表
            onLoad(){
                this.$axios.get('/api/index/getHotelList')
                    .then( (response) => {
                        console.log(response);
                        for (let i = 0;i < response.data.data.list.length; i++)
                        {
                            this.list.push({
                                hotel_name : response.data.data.list[i].hotel_name,
                                phone : response.data.data.list[i].phone,
                                phone2 : response.data.data.list[i].phone2,
                                hotel_id : response.data.data.list[i].hotel_id,
                            });
                        }
                        this.finished=true;
                    } ).catch( () => {
                        this.$toast('网络错误');
                        this.finished = true;
                });
            },
            // 编辑司机信息,id=司机id
            editDriver(hotel_id){
                this.$router.push({
                    name : 'AddHotel',
                    params : {hotel_id}
                });
            },
            // 删除
            delDriver(hotel_id,hotel_name,index){

                this.$dialog.confirm({
                    message : '删除 '+hotel_name,
                    title : '提示'
                }).then(() => {
                    this.$axios.post('/api/index/deleteHotel',this.$qs.stringify({hotel_id}))
                    .then((response) => {
                        this.$toast.success(response.data.msg);
                        if (response.data.code === 200)
                            this.list.splice(index,1);
                    }).catch(() => {
                        this.$toast('网络错误');
                    });
                }).catch(() => {
                    this.$toast('已取消');
                });
            },
            // 拨打电话
            call(tel) {
                window.location.href = 'tel://' + tel;
            },
        },
        created() {
            let admin_id = localStorage.getItem('admin_id');
            if (!admin_id)
            {
                this.$router.push({
                    name : 'adminLogin'
                });
            }
        }
    }
</script>

<style scoped>
    .btn-block{
        margin-bottom: 40px;
        padding: 5px 15px;
    }
</style>