<template>
    <div>
        <van-nav-bar :title="title"
        />
        <div style="margin-bottom: 60px;">
            <van-tabs v-model="active" v-if="hotel_id">
                <van-tab title="未送">
                    <van-grid>
                        <van-grid-item text="尾号" />
                        <van-grid-item text="时间" />
                        <van-grid-item text="人数" />
                        <van-grid-item text="状态" />
                    </van-grid>
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                        <van-grid v-for="(item,index) in list" :key="item.record_id">
                            <van-grid-item @click="call(item.phone)" :text="item.phone" />
                            <van-grid-item :text="item.leave_time" />
                            <van-grid-item :text="item.people+'人'" />
                            <van-grid-item @click="setState(item.record_id,item.phone,index)" :text="item.status"/>
                        </van-grid>
                    </van-list>
                </van-tab>
                <van-tab title="已送">
                    <van-cell-group>
                        <van-cell title="查询送客人数" :value="date" @click="show = true" label="送客人查询核对" is-link/>
                        <van-calendar v-model="show" @confirm="onConfirm" :min-date="new Date(2021,5,5)"/>
                    </van-cell-group>
                    <br>
                    <ul class="income">
                        <li>
                            <p>{{month}}</p>
                            <p>本月</p>
                        </li>
                        <li>
                            <p>{{yesterday}}</p>
                            <p>昨日</p>
                        </li>
                        <li>
                            <p>{{today}}</p>
                            <p>今日</p>
                        </li>
                    </ul>
                    <van-grid>
                        <van-grid-item text="尾号" />
                        <van-grid-item text="时间" />
                        <van-grid-item text="人数" />
                        <van-grid-item text="状态" />
                    </van-grid>
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                        <van-grid v-for="(item) in list" :key="item.record_id">
                            <van-grid-item @click="call(item.phone)" :text="item.phone" />
                            <van-grid-item :text="item.day" />
                            <van-grid-item :text="item.people+'人'" />
                            <van-grid-item :text="item.status"/>
                        </van-grid>
                    </van-list>
                </van-tab>
                <van-tab title="未接">
                    <van-grid>
                        <van-grid-item text="尾号" />
                        <van-grid-item text="时间" />
                        <van-grid-item text="人数" />
                        <van-grid-item text="状态" />
                    </van-grid>
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                        <van-grid v-for="(item,index) in list" :key="item.record_id">
                            <van-grid-item @click="call(item.phone)" :text="item.phone" />
                            <van-grid-item :text="item.leave_time" />
                            <van-grid-item :text="item.people+'人'" />
                            <van-grid-item @click="setState(item.record_id,item.phone,index)" :text="item.status"/>
                        </van-grid>
                    </van-list>
                </van-tab>
                <van-tab title="已接">
                    <van-grid>
                        <van-grid-item text="尾号" />
                        <van-grid-item text="时间" />
                        <van-grid-item text="人数" />
                        <van-grid-item text="状态" />
                    </van-grid>
                    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                        <van-grid v-for="(item) in list" :key="item.record_id">
                            <van-grid-item @click="call(item.phone)" :text="item.phone" />
                            <van-grid-item :text="item.leave_time" />
                            <van-grid-item :text="item.people+'人'" />
                            <van-grid-item :text="item.status"/>
                        </van-grid>
                    </van-list>
                </van-tab>
            </van-tabs>
        </div>
        <van-tabbar v-model="activeNav">
            <van-tabbar-item icon="home-o" @click="goBack">接送登记</van-tabbar-item>
            <van-tabbar-item icon="label" to="/hotelIndex">酒店查询</van-tabbar-item>
            <van-tabbar-item icon="setting-o" to="/hotel/setting">设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script type="text/javascript">
    export default {
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                page: 1, // 第一页
                id : 0,
                room : 0,
                active : 0,
                hotel_id : 0,
                month : 0,
                yesterday : 0,
                today : 0,
                title: '客人出行登记列表',
                date: '',
                show: false,
                status : 0,// 0=未送,1=已送
                record_type : 0,// 0=送客，1=接客
                activeNav : 1,
            };
        },
        methods: {
            onLoad() { // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求 axios
                this.$axios.post('/api/hotel_control/getRecordList', this.$qs.stringify({
                    page: this.page, // 请求页数
                    hotel_id : this.hotel_id,
                    status : this.status,
                    record_type: this.record_type
                })).then((response) => {
                    this.page++;
                    for (let i = 0; i < response.data.data.list.length; i++) {
                        this.list.push(response.data.data.list[i]);
                    }
                    this.today = response.data.data.today;
                    this.month = response.data.data.month;
                    this.yesterday = response.data.data.yesterday;
                    // 加载状态结束
                    this.loading = false; // 在每次请求完毕后，需要手动将loading设置为false，表示加载结束
                    // 数据全部加载完成
                    if (this.list.length >= response.data.data.count) { // 当加载的所有的数据大于或等于所有的数据后就停止加载
                        this.finished = true;
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },
            // 拨打电话
            call(tel) {
                window.location.href = 'tel://' + tel;
            },
            // 取消
            setState(record_id,phone,index){
                this.$dialog.confirm({
                    title : '提示',
                    message : phone+'-确定取消?'
                }).then(() => {
                    this.$axios.post('/api/hotel_control/cancelRecord',this.$qs.stringify({record_id})).then((response) => {
                        this.$toast(response.data.msg);
                        if (response.data.code === 200)
                            this.list.splice(index,1);
                    }).catch((error) => {
                        console.log(error);
                    });
                }).catch((error) => {
                    console.log(error);
                })
            },
            clickLeft(){
                this.$router.push({
                    name : 'qrcode',
                    params : {
                        hotel_id : this.hotel_id
                    }
                });
            },
            // 日期选择
            formatDate(date) {
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            },
            onConfirm(date) {
                this.list = [];
                this.show = false;
                this.date = this.formatDate(date);
                this.$axios.post('/api/hotel_control/getDateList', this.$qs.stringify({
                    hotel_id : this.hotel_id,
                    status : this.status,
                    record_type: this.record_type,
                    day : this.date,
                })).then((response) => {
                    for (let i = 0; i < response.data.data.list.length; i++) {
                        this.list.push(response.data.data.list[i]);
                    }
                    if (response.data.data.list.length === 0)
                        this.$toast(`${this.date}`+'无送客记录');
                    else
                        this.$toast(`${this.date}`+'有'+response.data.data.list.length+'条送客记录');
                }).catch((error) => {
                    console.log(error);
                });
            },
            goBack() {
                this.$router.push({
                    name : 'index',
                    params : {
                        hotel_id : localStorage.getItem('current_hotel_id')
                    }
                });
            }
        },
        mounted() { // 检查是否登录
            if (!localStorage.getItem('hotel_id') || localStorage.getItem('hotel_id') === 'undefined')
            {
                this.$router.push({name : 'hotelLogin'});
            }
            else
            {
                this.title = localStorage.getItem('hotel_name');
                this.hotel_id = localStorage.getItem('hotel_id');
            }
        },
        watch : {
            active : function (val) {
                this.finished=false;
                this.list=[];
                this.page=1;
                console.log(val);
                switch (val) {
                    case 0:
                        this.status=0;
                        this.record_type = 0;
                        console.log('待送');
                        break;
                    case 1:
                        this.status=1;
                        this.record_type = 0;
                        console.log('已送');
                        break;
                    case 2:
                        this.status=0;
                        this.record_type = 1;
                        console.log('待接');
                        break;
                    case 3:
                        this.status=1;
                        this.record_type = 1;
                        console.log('已接');
                        break;
                }
            }
        }
    }
</script>

<style>
    .van-col {
        padding: 10px 0;
        text-align: center;
    }
    .title {
        color: #969799;
        text-align: center;
    }
    .s{
        color: #F00;
    }
    .show-tel{
        position: absolute;
        top: 36px;
        z-index: 999;
        left: 33%;
    }
    .van-row{
        position: relative;
    }
    .income{
        display: flex;
    }
    .income li{
        flex: 1;
        text-align: center;
    }
    .income li p:last-child{
        font-size: 12px;
        color: #999999;
    }
    .income li p:first-child{
        color: #07c160;
    }
    .van-list .van-grid .van-grid-item:last-child .van-grid-item__text{
        color: red;
        font-weight: bold;
    }
</style>
