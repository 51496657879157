<template>
    <div>
        <van-nav-bar title="客户出行登记列表" left-text="未送" left-arrow @click-left="onClickLeft">
            <template #right>
                <van-icon name="search" size="18" @click="searchClick"/>
            </template>
        </van-nav-bar>
        <van-row gutter="20">
            <van-col span="8">今日</van-col>
            <van-col span="8">昨日</van-col>
            <van-col span="8">总计</van-col>
        </van-row>
        <van-row gutter="20">
            <van-col span="8">{{today}}</van-col>
            <van-col span="8">{{yesterday}}</van-col>
            <van-col span="8">{{total}}</van-col>
        </van-row>
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <van-row type="flex" class="title">
                <van-col span="6">日期</van-col>
                <van-col span="6">姓名</van-col>
                <van-col span="4">电话</van-col>
                <van-col span="6">房号</van-col>
                <van-col span="3">状态</van-col>
            </van-row>
            <van-row type="flex" v-for="item in list" :key="item.id">
                <van-col span="6">{{item.date}}</van-col>
                <van-col span="6" >{{item.name}}</van-col>
                <van-col span="4">
                    <van-icon name="phone" @click="call(item.tel)" />
                </van-col>
                <van-col span="6">{{item.hotel}}</van-col>
                <van-col span="3" @click="detail(item.id)">
                    <van-icon name="success" color="#07c160"/>
                </van-col>
            </van-row>
        </van-list>
    </div>
</template>

<script>
    export default {
        name: "Listed",
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                page: 1,
                today: 0,
                yesterday: 0,
                total: 0,
            };
        },
        methods : {
            onClickLeft(){
                this.$router.push({
                    name : 'list',
                });
            },
            onLoad() { // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求 axios
                this.$axios.post('/index/record/infoList', {
                    page: this.page,
                    isFinished : 1,
                }).then((response) => {
                    this.page++;
                    for (let i = 0; i < response.data.list.length; i++) {
                        this.list.push(response.data.list[i]);
                    }
                    this.today=response.data.today;
                    this.yesterday=response.data.yesterday;
                    this.total=response.data.total;
                    // 加载状态结束
                    this.loading = false; // 在每次请求完毕后，需要手动将loading设置为false，表示加载结束
                    // 数据全部加载完成
                    if (this.list.length >= response.data.count) { // 当加载的所有的数据大于或等于所有的数据后就停止加载
                        this.finished = true;
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },
            call(tel){
                location.href="tel://"+tel;
            },
            // 搜索内容
            searchClick(){
                this.$router.push({
                    name : 'search',
                });
            },
            detail(id){
                this.$router.push({
                    name : 'detail',
                    params : {
                        id : id,
                    }
                });
            }
        },
        created() { // 检查是否登录
            /*if (!localStorage.getItem('login')) {
                this.$router.push({
                    name: 'login',
                });
            }*/
        }
    }
</script>

<style scoped>

</style>