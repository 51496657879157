<template>
    <div class="login">
        <van-nav-bar
                title="酒店后台登陆"
                left-text="返回"
                @click-left="goBack"
                left-arrow
        />
        <div class="body">
            <van-cell-group >
                <van-field v-model="phone" label="账号：" placeholder="请输入登陆账号" :autofocus="true" required/>
                <van-field v-model="password" label="密码：" placeholder="请输入登陆密码" type="text" clearable required />
            </van-cell-group>
            <br>
            <br>
            <br>
            <van-button type="primary" block @click="login">立即登陆</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HotelLogin",
        data(){
            return {
                phone : '',
                password : '',
            };
        },
        methods : {
            goBack : function() {
                this.$router.push({
                    name : 'index',
                    params : {
                        hotel_id : localStorage.getItem('current_hotel_id'),
                    }
                });
            },
            login : function () {
                this.$axios.post('/api/login/hotelLogin',this.$qs.stringify({
                    phone : this.phone,
                    password: this.password,
                })).then((response) => {
                    this.$toast(response.data.msg);
                    if (response.data.code === 200)
                    {
                        localStorage.setItem('hotel_id',response.data.data.hotel_id);
                        localStorage.setItem('hotel_name',response.data.data.hotel_name);
                        this.$router.push({
                            name : 'hotelIndex'
                        });
                    }
                }).catch(() => {
                    console.error('network');
                })
            }
        }
    }
</script>

<style scoped>

</style>