<template>
    <div class="login">
        <van-nav-bar title="司机后台登陆" />
        <div class="body">
            <van-cell-group >
                <van-field v-model="phone" label="账号：" placeholder="请输入登陆账号" :autofocus="true" required/>
                <van-field v-model="password" label="密码：" placeholder="请输入登陆密码" type="password" clearable required />
            </van-cell-group>
            <van-button type="primary" block @click="login">立即登陆</van-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DriverLogin",
        data(){
            return {
                phone : '',
                password : '',
            };
        },
        methods : {
            login : function () {
                this.$axios.post('/api/driver/login',this.$qs.stringify({
                    phone : this.phone,
                    password: this.password
                })).then( (response) => {
                    this.$toast(response.data.msg);
                    if (response.data.code === 200)
                    {
                        localStorage.setItem('driver_id',response.data.data.driver_id);
                        localStorage.setItem("token",response.data.data.token)
                        this.$router.push({name : 'driverIndex'});
                    }
                });
            }
        }
    }
</script>

<style scoped>
.van-button{
    margin-top: 60px;
}
</style>