<template>
    <div>
        <van-nav-bar left-text="返回" left-arrow title="房态管理" @click-left="back" right-text="添加房间" @click-right="addRoom"/>
        <div class="room">
            <van-cell center title="总开关(不可预约时间：凌晨2点-11点整)">
                <template #right-icon>
                    <van-switch v-model="closed" size="18" active-color="rgb(7, 193, 96)" @change="openAll"/>
                </template>
            </van-cell>
            <div v-for="list in lists" :key="list.id">
                <van-cell center :title="list.room_name+'房间'">
                    <template #right-icon>
                        <van-switch v-model="list.is_closed" size="18" active-color="rgb(7, 193, 96)" @change="roomOp" @click="getId(list.id)"/>
                    </template>
                </van-cell>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RoomState",
        methods : {
            back(){
                this.$router.go(-1);
            },
            /*//删除房间
            room_delete(){
                console.log(123);
            }*/
            // 添加房间
            addRoom(){
                this.$router.push({
                    name : 'addRoom',
                });
            },
            roomOp(val){
                let code = val ? 1 : 0;
                this.$axios.post('/index/record/setRoomState',{
                    id : this.id,
                    code : code
                }).then((response) => {
                    this.$toast(response.data.message);
                });
            },
            getId(id){
                this.id = id;
            },
            // 打开所有的房间，关闭所有的房间
            openAll(){
                this.$axios.post('/index/record/setSystemConfig').then((response) => {
                    this.$toast(response.data.message);
                });
            }
        },
        data(){
            return {
                lists : [
                    {
                        id : 0,
                        room_name : 912,
                        is_closed : 1,
                    }
                ],
                id : 0,
                closed: true
            }
        },
        created() {
            this.$axios.post('/index/record/getRoom').then((response) => {
                this.lists=response.data.list;
                this.closed = response.data.open;
            });
            if (!localStorage.getItem('login')) {
                this.$router.push({
                    name: 'login',
                });
            }
        }
    }
</script>

<style scoped>
    .room{
        padding: 8px 16px;
    }
    .room button{
        margin: 0 5px;
    }
</style>