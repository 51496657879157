<template>
    <div>
        <van-nav-bar
                title="总后台"
        />
        <br>
        <br>
        <br>
        <van-grid clickable :column-num="2">
            <van-grid-item icon="manager" text="司机管理" to="/admin/driver" />
            <van-grid-item icon="hot" text="酒店管理" to="/admin/hotel" />
            <van-grid-item icon="service" text="出行记录" to="/admin/record" />
            <van-grid-item icon="lock" text="修改密码" to="/admin/Password" />
            <van-grid-item icon="clear" text="注销登陆" @click="logout" />
        </van-grid>
    </div>
</template>

<script>
    export default {
        name: "index",
        data(){
            return {};
        },
        methods : {
            onClickRight : function () {
                console.log()
            },
            // 注销账号
            logout(){
                this.$dialog.confirm({
                    title : '立即注销?',
                    message : '注销请重新登陆'
                }).then(() => {
                    localStorage.removeItem('admin_id');
                    this.$router.push({
                        name : 'adminLogin'
                    });
                }).catch(() => {});
            }
        },
        created() {
            if (this.$isDev)
                return;
            let admin_id = localStorage.getItem('admin_id');
            if (!admin_id)
            {
                this.$router.push({
                    name : 'adminLogin'
                });
            }
        }
    }
</script>

<style scoped>

</style>