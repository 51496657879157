import axios from "axios";
import config from '../config/base.config';
import router from "@/router";
// 获取域名
let domain = location.hostname;
if (domain === 'localhost'){
    domain = config.domain;
}

// 设置请求地址
axios.defaults.baseURL = `http://${domain}`
// 通过请求拦截器来设置请求token
axios.interceptors.request.use(function (config) {
    let token = localStorage.getItem('token');
    if (token){
        config.headers['token'] = token;
    }
    return config;
});
// 响应拦截器
axios.interceptors.response.use(function (response) {
    if (response.data.code*1 === 404){
        // 检测到没有token时，进行路由跳转
        return router.push({
            name: "driverLogin"
        });
    }
    // 返回响应数据
    return response;
});
// axios.defaults.headers.common['token'] = localStorage.getItem('token');
// 设置请求类型为application/x-www-form-urlencoded
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 导出axios配置
export default axios;
