import Vue from 'vue'
import App from './App.vue'
import api from './common/http.api'
import Vant from 'vant'
import 'vant/lib/index.css'
import router from "./router";
import qs from 'qs';



/*  此时因为当时报错了,所以才加上这个,但是不是这个原因是因为没有使用router-view
 const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
} */
Vue.config.productionTip = false;
// 给vue实例加上$是为了和vue进行统一
Vue.prototype.$axios = api;
Vue.prototype.$qs = qs;
// 安装插件
Vue.use(Vant);

Vue.prototype.$isDev = true; // 是否是开发模式

new Vue({
	render: h => h(App),
	router, // 等同于 router : router
	beforeCreate(){
		document.title='高铁站接送登记';
	}
}).$mount('#app');