<template>
    <div>
        <van-nav-bar title="司机列表" left-text="返回" @click-left="goBack" left-arrow/>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <van-grid>
                <van-grid-item text="姓名" />
                <van-grid-item text="电话1" />
                <van-grid-item text="电话2" />
                <van-grid-item text="操作" />
            </van-grid>
            <van-grid v-for="item in list" :key="item.id">
                <van-grid-item :text="item.name" @click="editDriver(item.id)"/>
                <van-grid-item :text="item.phone" @click="call(item.phone)"/>
                <van-grid-item :text="item.phone1" @click="call(item.phone)"/>
                <van-grid-item>
                    <van-button type="danger" size="mini" @click="delDriver(item.id,item.name)">删除</van-button>
                </van-grid-item>
            </van-grid>
        </van-list>
        <div class="btn-block">
            <van-button type="primary" block to="/admin/addDriver">添加司机</van-button>
        </div>
    </div>
</template>

<script>
    import Mock from 'mockjs';
    export default {
        name: "Driver",
        data(){
            return {
                loading : false,
                finished: false,
                list: [],
            };
        },
        methods : {
            goBack(){
                history.go(-1);
            },
            // 获取司机列表
            onLoad(){
                let _this = this;
                // 获取所有的司机
                this.$axios.get('api/index/getDrivers').then(response => {
                    console.log(response);
                    for (let i = 0; i < response.data.data.list.length; i++)
                    {
                        _this.list.push({
                            name : response.data.data.list[i].driver_name,
                            phone : response.data.data.list[i].phone,
                            phone1 : response.data.data.list[i].phone2,
                            id : response.data.data.list[i].driver_id,
                        });
                    }
                    this.finished=true;
                });
                console.log(Mock);
            },
            // 编辑司机信息,id=司机id
            editDriver(driver_id){
                console.log(driver_id);
                this.$router.push({
                    name : 'AddDriver',
                    params : {
                        driver_id
                    }
                });
            },
            // 删除
            delDriver(id,name){
                this.$dialog.confirm({
                    message : '立即删除?',
                    title : name
                }).then(() => {
                    this.$axios.get('api/index/delDriver?driver_id='+id).then((response)=>{
                        this.$toast(response.data.msg);
                    }).catch(() => {
                        this.$toast('网络错误哦');
                    });
                    this.$toast.success(id+'删除成功');
                }).catch(() => {
                    this.$toast('已取消');
                });
            },
            // 拨打电话
            call(tel) {
                window.location.href = 'tel://' + tel;
            },
        },
        created() {
            if (this.$isDev)
                return;
            let admin_id = localStorage.getItem('admin_id');
            if (!admin_id)
            {
                this.$router.push({
                    name : 'adminLogin'
                });
            }
        }
    }
</script>

<style scoped>
    .btn-block{
        margin-bottom: 40px;
        padding: 5px 15px;
    }
</style>