<template>
    <div>
        <van-nav-bar title="修改登陆密码" left-text="返回" @click-left="goBack" left-arrow/>
        <van-cell-group>
            <!-- 输入密码 -->
            <van-field v-model="password" type="password" label="密码" placeholder="请输入密码" clearable required/>
            <van-field v-model="repeat_password" type="password" label="重复密码" placeholder="请再次输入密码" clearable required/>
        </van-cell-group>
        <br>
        <p class="save" @click="login">
            <van-button block type="primary">立即修改</van-button>
        </p>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                password: '',
                repeat_password : ""
            };
        },
        methods : {
            // 修改密码
            login(){
                if (this.password !== this.repeat_password)
                    return this.$toast('两次密码不一致');
                if (!this.password)
                    return this.$toast('密码不能为空');
                this.$dialog.confirm({
                    title : '提示',
                    message : '确认立即修改密码?'
                }).then(() => {
                    this.$axios.post('/api/login/resetPwd',this.$qs.stringify({
                        password: this.password,
                        admin_id : localStorage.getItem('admin_id'),
                        login_type : 0,
                    })).then((response) => {
                        if(response.data.code === 200){
                            this.$toast(response.data.msg);
                            setTimeout(() => {
                                this.$router.push({
                                    name : 'adminLogin',
                                });
                                localStorage.removeItem('admin_id');
                            },3000);
                        }else{
                            this.$toast(response.data.msg);
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.$toast('系统繁忙');
                    });
                }).catch(() => {
                    console.log('cancel');
                });
            },
            // 返回
            goBack(){
                history.go(-1);
            },
        },
        created() {
            let admin_id = localStorage.getItem('admin_id');
            if (!admin_id)
            {
                this.$router.push({
                    name : 'adminLogin'
                });
            }
        }
    };
</script>

<style>
</style>
