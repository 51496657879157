<template>
    <div>
        <van-nav-bar
                :title="hotel_name"
                @click-left="hotelLogin"
                left-text="返回"
                left-arrow
                right-text="查询预约"
                @click-right="showInfo"
        />
        <p class="code-tip">接客信息登记</p>
        <van-cell-group>
            <van-field v-model="phone" label="电 话：" placeholder="请输入乘车人电话" type="tel" clearable required />
            <van-cell title="乘车人数" required>
                <template #default>
                    <van-stepper v-model="people"  button-size="26"  min="1" max="99"/>
                </template>
            </van-cell>
        </van-cell-group>
        <p class="save">
            <br>
            <br>
            <br>
            <van-button type="info" round @click="saveData" style="width: 50%;">{{btnText}}</van-button>
        </p>
        <audio src="../../assets/5c8949d53d6db90844.mp3" id="audio2" preload/>
    </div>
</template>

<script>
    export default {
        name: "GiveAway",
        data(){
            return {
                phone: '', // 手机号
                showPicker: false,
                currentTime: '12:00', // 时间选择
                leave_time: '', // 出发时间
                btnText : '立 即 预 约',
                id : 0,
                people  : 1, // 人数
                hotel_name : '',
                hotel_id : 0,
            };
        },
        methods : {
            onConfirm(time) {
                this.leave_time = time;
                this.showPicker = false;
            },

            // 保存资料
            saveData() {
                if (this.validate()) {
                    this.$dialog.confirm({
                        title: '提示',
                        message: '确认信息无误?'
                    }).then(() => {
                        // Using application/x-www-form-urlencoded format  进行预约
                        const params = new URLSearchParams();
                        params.append('phone',this.phone);
                        params.append('hotel_id',this.hotel_id);
                        params.append('people',this.people);
                        params.append('record_type',1);// 接客信息录入
                        this.$axios.post('/api/hotel_control/addRecord',params).then((response) => {
                            this.$toast(response.data.msg);
                            if (response.data.code === 200)
                            {
                                localStorage.setItem('record_id_1',response.data.data.record_id);
                                localStorage.setItem('record_id',response.data.data.record_id);
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                    }).catch(() => {
                        this.$notify('未预约成功!');
                    });
                }
            },
            // 验证用户输入的信息是否正确
            validate() {
                if (this.leave === '05:05' || this.leave === '14:35'){
                    this.$toast(this.leave+'不可预约');
                    return '';
                }
                if (this.phone.length !== 11) {
                    this.$toast('电话不正确');
                    return '';
                }
                return true;
            },
            play(){
                document.getElementById('audio2').play();
            },
            // 酒店管理员进行登陆
            hotelLogin(){
                history.go(-1);
            },
            showInfo(){
                if (!localStorage.getItem('record_id_1'))
                    return this.$toast('未登记');
                this.$router.push({
                    name : 'showInfo',
                });
            }
        },
        mounted() {
            var current_hotel_id = localStorage.getItem('current_hotel_id');
            if (current_hotel_id == 'undefined' || !current_hotel_id)
                return this.$router.push({'name' : '/'});
            else
            {
                this.hotel_id = current_hotel_id;
                this.hotel_name = localStorage.getItem('hotel_name');
            }
        }
    }
</script>

<style scoped>
    .save {
        margin: 0 16px;
        text-align: center;
    }
</style>