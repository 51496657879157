<template>
	<div>
		<van-nav-bar
				:title="hotel_name"
		/>
		<div class="body">
			<van-button size="large" text="送客 信息登记" type="primary" block to="/hotel/giveAway"/>
			<br>
<!--			<van-button size="large" text="接客 信息登记" block type="info" to="/hotel/pickUp"/>-->
		</div>
		<br />
		<p class="save">
			<br>
			<br>
			<van-notice-bar wrapable :scrollable="false" style="text-align: left">
				<div>1. 建议出发时间提前1个小时以上,以免耽误您的行程.</div>
				<div>2. 请安排好时间,提前5-10分钟下楼等车,如超时未送,车队概不负责.</div>
				<div>3. 请填写真实的电话号码方便司机与您联系,若联系不上后果自负.</div>
				<div>4. 超过14:30分后不可再预约送站服务。如需送站请联系相关负责人.</div>
			</van-notice-bar>
		</p>
		<audio src="../assets/5c8949d53d6db90844.mp3" id="audio2" preload/>
		<van-tabbar v-model="active">
			<van-tabbar-item icon="home-o">接送登记</van-tabbar-item>
			<van-tabbar-item icon="label" to="/hotelIndex">酒店查询</van-tabbar-item>
			<van-tabbar-item icon="setting-o" to="/hotel/setting">设置</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	export default {
		// 属性集合
		data() {
			return {
				phone: '', // 手机号
				showPicker: false,
				currentTime: '12:00', // 时间选择
				leave_time: '', // 出发时间
				btnText : '立 即 预 约',
				id : 0,
				people  : 1, // 人数
				hotel_name : '',
				hotel_id : 0,
				active: 0,
			}
		},
		// 方法集合
		methods: { // 所有的方法
			onConfirm(time) {
				this.leave_time = time;
				this.showPicker = false;
			},
			// 时间格式化
			formatter(type, val){
				if (type === 'hour') {
					return `${val} 时`;
				} else if (type === 'minute') {
					return `${val} 分`;
				}
				return val;
			},
			// 保存资料
			saveData() {
				if (this.validate()) {
					this.$dialog.confirm({
						title: '提示',
						message: '确认信息无误?'
					}).then(() => {
						if (this.$route.params.isModify === 1){ // 修改资料
							let info = JSON.parse(localStorage.getItem('user'));
							this.$axios.post('/index/record/recordUpdate',{
								id : info.id,
								phone : this.phone,
								leave : this.leave,
								person : this.person,
							}).then((response) => {
								if (response.data.code > 0){
									this.id = info.id;
									localStorage.setItem('user',JSON.stringify(this.$data));
									this.$toast(response.data.message);
									this.$router.push({
										name : 'show',
									})
								}else {
									this.$toast(response.data.message);
								}
							}).catch((error) => {
								console.log(error);
							});
							return '';
						}
						// 进行预约
						this.$axios.post('/api/hotel_control/addRecord',this.$qs.stringify(this.$data)).then((response) => {
							this.$toast(response.data.msg);
						}).catch((error) => {
							console.log(error);
						});
					}).catch(() => {
						this.$notify('未预约成功!');
					});
				}
			},
			// 验证用户输入的信息是否正确
			validate() {
				if (this.leave === '05:05' || this.leave === '14:35'){
					this.$toast(this.leave+'不可预约');
					return '';
				}
				if (this.phone.length !== 11) {
					this.$toast('电话不正确');
					return '';
				}
				if (this.leave === ''){
					this.$toast('时间未选择');
					return '';
				}
				return true;
			},
			// 时间过滤
			filter(type, options) {
				if (type === 'hour'){
					return  options.filter((hour => {
						if (hour >= 5 && hour <= 14){
							return hour;
						}
					}));
				}
				if (type === 'minute'){
					let minutes;
					minutes = options.filter((minute => {
						minute=Number(minute);
						if (minute === 0)
							return minute+'0';
						else if (minute === 20)
							return  minute;
						else if (minute === 40)
							return minute;
					}));
					return minutes;
				}
				return options;
			},
			play(){
				document.getElementById('audio2').play();
			},
		},
		// 获取是否是修改
		created (){
			// 查询该酒店id是否存在
			this.hotel_id = this.$route.params.hotel_id;
			this.$axios.get('/api/hotel_control/checkHotel?hotel_id='+this.hotel_id).then((response) => {
				if (response.data.code === 400)
					this.$router.push({name : 'emptyHotel'});
				console.log(response);
				this.hotel_name = response.data.data.hotel_name;
				localStorage.setItem('hotel_name',this.hotel_name);
				localStorage.setItem('current_hotel_id',this.hotel_id);
			});
			console.log(this.$route.params.hotel_id);
		},
	}
</script>

<style>
	.save {
		margin: 0 16px;
		text-align: center;
	}
	.van-picker-column__item--selected{
		color: #1989fa!important;
		font-weight: 800;
	}
	.code-tip{
		text-align: center;
		color: #f00;
		font-size: 14px;
	}
</style>
